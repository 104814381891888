* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    min-height: 100vh;
    max-width: 100vw;
}

.main {
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.main_content {
    width: 100%;
    min-height: 80vh;
    padding-bottom: 20px;
}

.main_content header img {
    max-width: 180px;
    margin-top: 12px;

}

.propagating {
    min-width: 200px;
    width: 10%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.propagating img {
    max-width: 100%;
    border: 2px solid rgb(121, 121, 121);
}

header {
    text-align: center;
}

.parameters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 21px;
}

.parameterBig {
    padding: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.back {
    text-align: center;
}

.back p {
    color: gray;
    cursor: pointer;
    max-width: 120px;
    margin: 0 auto;
    border-radius: 16px;
    padding: 4px;
}

.parameters_item {
    min-width: 25%;
    text-align: center;
    border: 1px solid gray;
    padding: 12px;
}

.calc {
    text-align: center;
    margin-top: 18px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calc p {
    padding: 10px;
}

.calc input {
    padding: 6px 3px;
    max-width: 280px;
    width: 100%;
    margin: 10px;
    border: 0;
    outline: 0;
    border-bottom: 2px solid lightgray;
}

.calc input:disabled,
.calc button:disabled {
    opacity: 0.7;
}

.calc button {
    min-width: 140px;
    margin-top: 28px;
    padding: 6px 8px;
    background: linear-gradient(180deg, rgba(252, 181, 37, 1) 0%, rgba(255, 136, 0, 1) 100%);
    -webkit-box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.40);
    box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.40);
    border: 0;
    border-radius: 3px;
    outline: 0;
    color: white;
    cursor: pointer;
    transition: all ease 0.7s;
}

.calc button:hover {
    transform: scale(1.08);
}

.calc button:disabled:hover {
    transform: scale(1);
}


@media screen and (max-width: 920px) {
    .main {
        flex-direction: column;
    }

    .propagating:first-of-type {
        align-items: flex-start;
    }

    .propagating {
        min-width: 100%;
        min-height: 90px;
        align-items: flex-end;
        padding-bottom: 0;
    }


}